import React from "react"
import Page from "../components/page"
import ResponsiveImage from "../components/responsive-image"
import { themes } from "../styles/themes"
import paths from "../utils/paths"

export default function Contact({ labels, ...props }) {
  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.contact}
      description={labels.contactDescription}
      keywords={labels.contactKeywords}
      ogUrl={paths(labels).contact}
      ogImage=""
    >
      <div className="contact-text-container">
        <p>{labels.contactLookingForwardText}</p>
        <br />
        <p>{labels.contactTogetherText}</p>
        <br />
        <p>
          <span>{labels.contactIgorProdanovicText}</span>
          <span>{labels.contactArchitectAndDesignerText}</span>
        </p>
        <br />
        <br />
        <a href="tel:+385914657168" target="_blank" rel="noreferrer">
          <p>
            <span>{`${labels.contactMobileTelephoneNumberLetterText} `}</span>
            <span className="contact-mobile-telephone-number-text">
              {labels.contactMobileTelephoneNumberText}
            </span>
          </p>
        </a>
        <br />
        <a
          href="mailto:igor.prodanovic65@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          <p>
            <span>{`${labels.contactEmailLetterText} `}</span>
            <span className="contact-email-text">
              {labels.contactEmailGmailText}
            </span>
          </p>
        </a>
        <br />
        <a
          href="mailto:igor@prodanovicstudio.com"
          target="_blank"
          rel="noreferrer"
        >
          <p>
            <span>{`${labels.contactEmailLetterText} `}</span>
            <span className="contact-email-text">
              {labels.contactEmailProdanovicStudioText}
            </span>
          </p>
        </a>
        <br />
        <br />
        <br />
        <a
          href="https://www.instagram.com/sexyinterijeri"
          target="_blank"
          rel="noreferrer"
        >
          <div className="contact-social-container">
            <ResponsiveImage
              src="/images/common/logo-instagram-white.png"
              fit="scale-down"
              containerClass="img-container contact-social-img-container"
            />
            <p className="contact-instagram-text">
              <span>{labels.contactInstagramText}</span>
            </p>
          </div>
        </a>
        <br />
        <a
          href="https://www.facebook.com/Sexyinterijeri-100600784944415"
          target="_blank"
          rel="noreferrer"
        >
          <div className="contact-social-container">
            <ResponsiveImage
              src="/images/common/logo-facebook-white.png"
              fit="scale-down"
              containerClass="img-container contact-social-img-container"
            />
            <p className="contact-facebook-text">
              <span>{labels.contactFacebookText}</span>
            </p>
          </div>
        </a>

        {/* <br />
        <br />
        <p>
          <span className="contact-facebook-text">
            {labels.contactFacebookText}
          </span>
          <span>{"   "}</span>
          <span className="contact-instagram-text">
            {labels.contactInstagramText}
          </span>
        </p> */}
      </div>
    </Page>
  )
}
